import React from "react";

import Layout from "../components/layout-main";
import { ContactForm, FormTypes } from "../components/form-contact";
import { HeroSplashSection } from "../components/sections/hero-splash";
import { SocialIcons } from "../components/social-icons";
import { CalendlyEventTypes } from "../components/form-calendly";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

const Nfda2023Page = ({ location }) => {
  React.useEffect(() => {
    let input = document.querySelector("input[type=email]");
    input && input.focus();
  }, []);

  return (
    <Layout location={location} pageTitle="Contact Us" hideSubscribe>
      <HeroSplashSection
        id="hero-splash"
        title={
          <>
            Are you prepared
            <br />
            for the FTC changes?
          </>
        }
        subtitle={"Start serving families online today."}
        description={
          "We've analyzed every funeral home website in America—sign up below, and we'll email you our detailed report of best practices and pitfalls to avoid. Or head to Booth #963 to set up your online planner in 15 minutes."
        }
        imgSrc={"https://media.afterword.com/site/pricing-map-2022.png"}
        imgWidth={["100%", "600px"]}
        cta={
          <ContactForm
            buttonVariant="blueButton"
            formType={FormTypes.NFDA_2023}
            formTitle=""
            formOutro="Thanks for signing up. Our team will reach out to you shortly, or head to Booth #963."
            hideImage={true}
            buttonText="Sign up"
            hideCalendly
          />
        }
      ></HeroSplashSection>

    </Layout>
  );
};

export default Nfda2023Page;
